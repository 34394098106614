import { destr } from 'destr'
import type { NuxtError } from 'nuxt/app'

interface StoryblokErrorString {
  message?: string
  status?: number
  response?: string
}

export function storyblokCatchError(error: string | unknown): Partial<NuxtError> {
  if (typeof error === 'string') {
    const e = destr(error) as StoryblokErrorString

    return {
      statusCode: e?.status || 500,
      statusMessage: e?.message,
    }
  }

  return {
    statusCode: 500,
    statusMessage: '',
  }
}
