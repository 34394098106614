import { c as UseSeoMetaInput } from '@unhead/vue/dist/shared/vue.8eef6ffc'
import { SbBlokData } from '@storyblok/vue/dist/types'
import type { StoryblokStory } from '~/types/storyblok.custom'

export function storyblokSeoMeta(story: Ref<StoryblokStory<SbBlokData>>): UseSeoMetaInput {
  const _story = ref<StoryblokStory>(story)
  return {
    title: _story.value.content.meta?.title || _story.value.name,
    description: _story.value.content.meta?.description,
  }
}
